<template>
    <v-img :src="imageData" :height="height" :width="width" :max-height="maxHeight" :max-width="maxWidth" :min-height="minHeight" :min-width="minWidth" :contain="contain" :aspect-ratio="aspectRatio" :content-class="contentClass"></v-img>
</template>
<script>
import { mapState } from 'vuex';

export default {
    /**
     * the `etag` prop is optional, but can be used to reload an image (e.g. after a new image was uploaded, change the etag to Date.now() in parent component)
     * the other props are passed to v-img
     *
     * NOTE: Vue automatically translates hyphenated names such as "max-width" to camelCase "maxWidth" so when using the component you write :max-width="192" but here we receive it as "maxWidth".
     */
    props: ['brandprofile', 'etag', 'height', 'width', 'maxHeight', 'maxWidth', 'minHeight', 'minWidth', 'contain', 'aspectRatio', 'contentClass'],

    data: () => ({
        content: null,
        contentType: null, // 'image/png'
    }),

    computed: {
        ...mapState({
            realm: (state) => state.realm,
            // realmInfo: (state) => state.realmInfo,
        }),
        imageData() {
            if (this.contentType && this.content) {
                return `data:${this.contentType};base64,${this.content}`;
            }
            return '';
        },
        // brandprofile() {
        //     return this.realmInfo?.brandprofile;
        // },
    },

    watch: {
        etag() {
            this.loadImage();
        },
        // realmInfo() {
        //     this.loadImage();
        // },
        brandprofile() {
            this.loadImage();
        },
    },

    methods: {
        async loadBrandProfileImage() {
            try {
                this.$store.commit('loading', { loadBrandProfileImage: true });
                // TODO: cache it by brandprofile so we're not reloading the image all the time if it's one we already loaded
                const response = await this.$client.brandprofile().image.get({ alias: this.brandprofile, intent: 'logotype', mode: 'light' });
                if (response) {
                    this.contentType = response.content_type;
                    this.content = response.content_base64;
                }
            } catch (err) {
                console.log('loadBrandProfileImage failed', err);
            } finally {
                this.$store.commit('loading', { loadBrandProfileImage: false });
            }
        },
        async loadCustomImage() {
            try {
                this.$store.commit('loading', { loadCustomImage: true });
                const response = await this.$client.realm(this.$route.params.realm).currentRealm.getImage({ label: 'logo' });
                if (response) {
                    this.contentType = response.content_type;
                    this.content = response.content_base64;
                }
            } catch (err) {
                console.log('loadCustomImage failed', err);
            } finally {
                this.$store.commit('loading', { loadCustomImage: false });
            }
        },
        async loadImage() {
            if (this.brandprofile) {
                await this.loadBrandProfileImage();
            } else {
                await this.loadCustomImage();
            }
        },
    },

    mounted() {
        this.loadImage();
    },

};
</script>
