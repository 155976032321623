<template>
    <v-snackbar v-model="display" :timeout="duration" top :color="snackbarColor" class="mt-0 pt-0" :multi-line="isMultiLine">
        <h1 class="text-body-2 font-weight-bold" v-if="attr.headline">{{ attr.headline }}</h1>
        <p class="my-0">{{ attr.message }}</p>
        <template v-slot:action="{ attrs }">
            <v-btn text :class="buttonClass" @click="display = false" v-bind="attrs" v-if="buttonDisplay"><font-awesome-icon :icon="['fas','check']" fixed-width/></v-btn>
        </template>
    </v-snackbar>
</template>

<script>
export default {

    props: {
        attr: {
            type: Object,
            default: () => ({
                headline: '', // optional -- use if you need a multi-line snackbar
                message: '', // use this for 1-line snackbars
                type: '', // 'error', 'warn', 'info', 'success'
                withButton: true,
                duration: 2000, // milliseconds
            }),
        },
    },

    data() {
        return {
            display: false,
        };
    },

    computed: {
        snackbarColor() {
            switch (this.attr.type) {
            case 'error':
                return 'red';
            case 'warn':
                return 'amber';
            case 'info':
                return 'blue';
            case 'success':
                return 'green';
            default:
                return 'gray';
            }
        },
        buttonClass() {
            switch (this.attr.type) {
            case 'error':
                return 'red white--text';
            case 'warn':
                return 'amber white--text';
            case 'info':
                return 'blue white--text';
            case 'success':
                return 'green white--text';
            default:
                return 'gray white--text';
            }
        },
        buttonDisplay() {
            return this.attr.withButton;
        },
        isMultiLine() {
            return typeof this.attr.headline === 'string' && this.attr.headline.length > 0;
        },
        duration() {
            return this.attr.duration ?? 2000;
        },
    },

    watch: {
        attr() {
            // automatically display snackbar whenever attributes change
            this.display = true;
        },
    },
};
</script>
