var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"no-print"},[_c('v-app-bar',{attrs:{"fixed":"","fade-on-scroll":"","elevation":"1","dense":"","color":"white","app":""}},[(!_vm.isAuthenticated)?_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":_vm.redirectToMain}},[_c('v-toolbar-title',{staticClass:"indigo--text text-overline",staticStyle:{"font-size":"16px!important"}},[_vm._v(" LoginFront ")])],1):_vm._e(),(_vm.isAuthenticated)?_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":_vm.redirectToDashboard}},[_c('v-toolbar-title',{staticClass:"indigo--text text-overline",staticStyle:{"font-size":"16px!important"}},[_vm._v(" LoginFront ")])],1):_vm._e(),_c('v-progress-linear',{attrs:{"active":_vm.isLoading,"indeterminate":_vm.isLoading,"absolute":"","bottom":"","color":_vm.progressBarColor}}),_c('v-spacer'),_c('v-menu',{attrs:{"offset-y":"","open-on-click":"","open-on-hover":"","close-delay":"100"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"indigo"}},'v-btn',attrs,false),on),[_c('font-awesome-icon',{attrs:{"icon":['fas','question'],"fixed-width":""}})],1)]}}]),model:{value:(_vm.helpMenu),callback:function ($$v) {_vm.helpMenu=$$v},expression:"helpMenu"}},[_c('v-card',[_c('v-card-text',[_vm._v(" Questions or comments? "),_c('a',{attrs:{"href":_vm.contactURL,"target":"_blank"}},[_vm._v("Contact us!")])])],1)],1),(!_vm.isAuthenticated)?_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.smAndUp),expression:"$vuetify.breakpoint.smAndUp"}]},[_c('v-tabs',{attrs:{"slider-color":"indigo","right":""}},[_c('v-tab',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"to":_vm.redirectToMain}})],1)],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.xsOnly),expression:"$vuetify.breakpoint.xsOnly"}]},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":""}},on),[_c('font-awesome-icon',{staticClass:"indigo--text",attrs:{"right":"","icon":"bars","fixed-width":""}})],1)]}}],null,false,1900345782)},[_c('v-tabs',{attrs:{"vertical":"","slider-color":"indigo"}},[_c('v-tab',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"to":_vm.redirectToMain}})],1)],1)],1)]):_vm._e(),(_vm.isAuthenticated)?_c('div',[_c('v-tabs',{attrs:{"slider-color":"indigo"}},[_c('v-tab',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"to":_vm.redirectToDashboard}}),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-tab',_vm._g({staticClass:"indigo--text"},on),[_c('Avatar',{attrs:{"attr":_vm.mainProfile,"size":36}}),_c('font-awesome-icon',{staticClass:"pl-2 indigo--text",attrs:{"icon":"caret-down","fixed-width":"","size":"1x"}})],1)]}}],null,false,2978919915)},[_c('v-list',{staticClass:"ma-0 pa-0"},[_c('div',[_c('v-tabs',{staticClass:"menutabs",attrs:{"vertical":"","slider-color":"indigo"}},[_c('v-tab',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"to":_vm.redirectToDashboard}}),_c('v-tab',{attrs:{"to":_vm.redirectToPreferences}},[_vm._v("Preferences")]),_c('v-divider'),_c('v-tab',{on:{"click":_vm.onLogout}},[_vm._v("Logout")])],1)],1)])],1)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }